.pageHeight {
  height: calc(100vh - 73px);
  @media screen and (min-width: 768px) {
    height: 700px;
  }
}
.duploLogoOpaque {
  transform: translate(100px, 250px) scale(0.5);
  @media screen and (max-width: 767px) {
    transform: translate(100px, 250px) scale(0.5);
  }
}
.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.modalContent {
  width: 425px;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: -10px;
    width: 100vw;
  }
}
.radioBox input {
  display: none;
}
.radioBox span {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-color: #dcdde5;
  border-radius: 180px;
  @media screen and (max-width: 767px) {
    border-radius: 3px;
  }
}
.radioBox input:checked + span {
  background-color: #2db49e;
  background-image: url(../svg/whiteTick.svg);
  background-repeat: no-repeat;
  background-position: center;
}
