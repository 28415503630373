.pageHeight {
  height: calc(100vh - 73px);
  @media screen and (min-width: 768px) {
    height: 700px;
  }
}
.duploLogoOpaque {
  transform: translate(100px, 250px) scale(0.5);
  @media screen and (max-width: 767px) {
    transform: translate(100px, 250px) scale(0.5);
  }
}
