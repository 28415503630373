.duploLogoOpaque {
  transform: translate(100px, 250px) scale(0.5);
  @media screen and (max-width: 767px) {
    transform: translate(100px, 250px) scale(0.5);
  }
}
.pageHeight {
  height: calc(100vh - 73px);
  @media screen and (min-width: 768px) {
    height: 700px;
  }
}
.historyDetails {
  @media screen and (min-width: 768px) {
    box-shadow: 0px 12px 26px rgba(92, 105, 110, 0.1);
    border-radius: 20px;
    background-color: #ffffff;
  }
}
.noHistory {
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 9.68813px 20.9909px -5.65141px rgba(92, 105, 110, 0.1);
  border-radius: 5px;
}