.backgroundBox {
  background-color: #103129;
  background-image: url("../svg/bgPattern.svg");
  width: 720px;
  border-radius: 20px;
  height: 225px;
  z-index: -1;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 150px;
    border-radius: 0px;
  }
}
.logo {
  margin-top: 25px;
  max-width: 75px;
  @media screen and (max-width: 767px) {
    margin-top: 5px;
    transform: scale(0.7);
  }
}
.formElement {
  margin-top: -50px;
  @media screen and (min-width: 768px) {
    margin-top: -100px !important;
    margin: auto;
  }
}
.inputField {
  width: 100%;
  background-color: #fff;
  border-radius: 20px 20px 0px 0px;
  @media screen and (min-width: 768px) {
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03),
      0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778),
      0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222),
      0px 20px 13px rgba(0, 0, 0, 0.015),
      0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778),
      0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
    max-width: 600px;
    min-height: 450px;
    border-radius: 20px 20px 20px 20px;
  }
}

.duploLogoOpaque {
  transform: translate(100px, 250px) scale(0.5);
  @media screen and (max-width: 767px) {
    transform: translate(100px, 250px) scale(0.5);
  }
}
.pageHeight {
  min-height: 100vh;
  @media screen and (min-width: 768px) {
    min-height: 700px;
  }
}
