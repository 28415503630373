
.react-calendar {
    width: 100%;
    max-width: 100%;
    font-family: 'Euclid-circular B';
    line-height: 1.125em;
    padding: 34px;
    background: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-top: 10px;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    color: #00000099;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .react-calendar__navigation__label__labelText::after {
    content: url('../svg/calendarDownArrow.svg');
    position:absolute;
    padding-left: 10px;
    top:-2px;
  }
  .react-calendar__navigation__label{
    position: relative;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:disabled {
    background-color: #fff;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.6);
    border-bottom: none;
  }
  abbr {
    text-decoration: none;
    cursor: default !important;
  }
  
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--weekend {
    
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #00000066;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 20px 8px;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    cursor: pointer;
  }
  .react-calendar__tile:disabled {
    color: #DCDDE5;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #0000001a;
    border-radius: 8px;

  }
  .react-calendar__tile--now {
    
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    
  }
  .react-calendar__tile--hasActive {
    background: #2DB49E;
    border-radius: 8px;
    color: #FFFFFF;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: #2DB49E;
  }
  .react-calendar__tile--active {
    border: 1px solid #28D278 !important;
    border-radius: 8px;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #FFFFFF;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
  }