
.modalBackground {
    background:transparent;
    position: fixed;
    z-index: 9; 
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: none;
}

.dropdownModal {
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #68717f12;
  z-index: 20;
  left: 0;
  animation: dropdownAnimation 1s 1;
  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0px;
    height: 415px;
    animation: mobileDropdownAnimation 1s 1;
  }
}
@keyframes dropdownAnimation {
  0% {
    opacity: 0;
    margin-top: 0px;
  }
  100% {
    opacity: 1;
    margin-top: 10px;
  }
}
@keyframes mobileDropdownAnimation {
  0% {
    opacity: 0;
   height: 0px;
  }
  100% {
    opacity: 1;
    height: 415px;
  }
  
}

