.modalBackdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }
  
  .modalContent {
    width: 640px;
    background-color: white;
    z-index: 100;
    border-radius: 10px;
    @media screen and (max-width: 767px) {
      position: absolute;
      bottom: -10px;
      width: 100vw;
    }
  }
  @keyframes loanModalAnimation {
    0% {
      height: 0;
    }
    100% {
      height: 674px;
    }
  }
  @keyframes businessModalAnimation {
    0% {
      height: 0;
    }
    100% {
      height: 610px;
    }
  }
  .loanRepaymentContent{
    @media screen and (max-width: 767px) {
      height: 674px;
      animation: loanModalAnimation;
      animation-iteration-count: 1;
      animation-duration: 1s;
    }
  }
  .buisnessRepaymentContent{
    @media screen and (max-width: 767px) {
      height: 610px;
      animation: businessModalAnimation;
      animation-iteration-count: 1;
      animation-duration: 1s;
    }
  }