.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.modalContent {
  width: 460px;
  min-height: 200px;
  background-color: #fff;
  color: #ffffff;
  padding: 25px;
  backdrop-filter: blur(60px);
  border-radius: 8px;
  width: 768px;
  height: 716px;
}

.ol_reset {
  counter-reset: item;
  list-style: decimal;

  li {
    display: block;
    margin-top: 10px;
  }

  li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
  }
}

