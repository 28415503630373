.duploLogoOpaque {
  transform: translate(100px, 250px) scale(0.5);
  @media screen and (max-width: 767px) {
    transform: translate(100px, 250px) scale(0.5);
  }
}
.loanDetails {
  box-shadow: 0px 12px 26px rgba(92, 105, 110, 0.1);
  background-color: #ffffff;
  border-radius: 5px;
  @media screen and (min-width: 768px) {
    border-radius: 20px;
  }
}
.pageHeight {
  height: calc(100vh - 73px);
  @media screen and (min-width: 768px) {
    height: 700px;
  }
}
.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.modalContent {
  width: 430px;
  background-color: white;
  z-index: 100;
  border-radius: 10px;
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 500px;
    animation: modalAnimation;
    animation-iteration-count: 1;
    animation-duration: 1s;
  }
}
@keyframes modalAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 500px;
  }
}
