.modalAnimation {
  height: 200px;
  animation: modalAnimation;
  animation-iteration-count: 1;
  animation-duration: 1s;
  position: absolute;
}
@keyframes modalAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 200px;
  }
}
.dropDown {
  position: relative;
  min-width: 174px;
}
.dropdown,
.dropdown * {
  z-index: 10;
}
.dropdown .dropDownSelectedOption {
  background-color: #fff;
  border-radius: 8px;
  cursor: pointer;
}
.dropdownContent {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  z-index: 10;
  left: 0;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  margin-top: 20px;
  color: #192850;
  background-color: #fff;
}
.pageHeight {
  height: 100vh;
  @media screen and (min-width: 768px) {
    height: 700px;
  }
}
.duploLogoOpaque {
  transform: translate(100px, 250px) scale(0.5);
  @media screen and (max-width: 767px) {
    transform: translate(100px, 250px) scale(0.5);
  }
}

.formBackground {
  @media screen and (min-width: 768px) {
    min-width: 340px;
    border: 1px solid #e1e7eb;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03),
      0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0227778),
      0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0182222),
      0px 20px 13px rgba(0, 0, 0, 0.015),
      0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0117778),
      0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00722222);
    border-radius: 20px;
  }
}
.inputField:disabled {
  background: #f6f7f9;
  border: 1px solid #c7cad3;
}

.checkBox input {
  display: none;
}
.checkBox span {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-color: #dcdde5;
  border-radius: 5px;
}
.checkBox input:checked + span {
  background-color: #2db49e;
  background-image: url(../svg/whiteTick.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.bankNameModal {
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #68717f12;
  z-index: 20;
  left: 0;
  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0px;
  }
}

.modalBackdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
  
}
.modalContent {
  width:428px;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: -10px;
    width: 100vw;
  }
}
@keyframes modalAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 580px;
  }
}
.kycSuccessContent{
  @media screen and (max-width: 767px) {
    height: 580px;
    animation: modalAnimation;
    animation-iteration-count: 1;
    animation-duration: 1s;
  }
}