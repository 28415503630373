.businessCard {
  background-color: #103129;
  border: 1px solid #289b70;
  border-radius: 5px;
  min-height: 165px;
  background-image: url("../svg/bgPattern.svg");
  @media screen and (min-width: 768px) {
    border-radius: 20px;
  }
}
.businessAccount {
  background-color: #103129;
  border: 3px solid #1a4230;
  border-radius: 8px;
}
.creditLimit {
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0px 12px 26px -7px rgba(92, 105, 110, 0.1);
  border-radius: 5px;
  @media screen and (min-width: 768px) {
    background: #ffffff;
    opacity: 0.65;
    border: 1px solid #2db49e;
    border-radius: 20px;
  }
}
.activeLoans {
  // border: 1px solid rgba(0, 0, 0, 0.05);
  // box-shadow: 0px 9.68813px 20.9909px -5.65141px rgba(92, 105, 110, 0.1);

  border-radius: 5px;
  @media screen and (min-width: 768px) {
    border-radius: 20px;
  }
}

.viewMoreBtn {
  border-radius: 0 20px 0 20px;
  margin: 0 0 0 auto;
  border: 1px solid #0000001a;
}

.dropDown {
  z-index: 10000;
  position: relative;
  min-width: 174px;
}
.dropdown,
.dropdown * {
  z-index: 10;
}
.dropdown .businessAccount {
  background-color: #103129;
  border: 3px solid #1a4230;
  border-radius: 8px;
  cursor: pointer;
}
.dropdownContent {
  min-width: 286px;
  position: absolute;
  left: 0;
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  margin-top: 20px;
  background-color: #103129;
}
.pageHeight {
  height: calc(100vh - 73px);
  @media screen and (min-width: 768px) {
    height: 700px;
  }
}
.duploLogoOpaque {
  transform: translate(100px, 250px) scale(0.5);
  @media screen and (max-width: 767px) {
    transform: translate(100px, 250px) scale(0.5);
  }
}
.businessCategoryModal {
  height: calc(100vh - 73px);
}

.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.modalContent {
  width: 430px;
  background-color: white;
  z-index: 100;
  border-radius: 10px;
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 456px;
    animation: modalAnimation;
    animation-iteration-count: 1;
    animation-duration: 1s;
  }
}
@keyframes modalAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 456px;
  }
}
.currentBusinessModal {
  height: 400px;
  z-index: 100;
  border-radius: 10px;
  background-color: white;
  animation: businessModalAnimation;
  animation-iteration-count: 1;
  animation-duration: 1s;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
}
@keyframes businessModalAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 400px;
  }
}

.currentBusinessDropdown {
  transition: width 0.3s, max-height 0.3s;
  overflow: hidden;
  max-height: 60px;
  width: 174px;
  position: absolute;
  background: #103129;
  // border: 3px solid #1a4230;
  border-radius: 8px;
  position: absolute;
  top: -20px;
  z-index: 10;
  right: 0;
}

.openCurrentBusinessDropdown {
  max-height: var(--max-height);
  width: var(--max-width);
  z-index: 10;
}
