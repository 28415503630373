@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Euclid-circular B";
  src: url("./assets/fonts/Euclid_Circular_B_Regular.ttf");
}

/* @font-face {
  font-family: 'Euclid-circular B';
  src: url('https://d1f8g8i172sxu4.cloudfront.net/font/Euclid_Circular_B_Regular.ttf');
} */

body {
  margin: 0;
  font-family: "Euclid-circular B";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  zoom: 96%;
}

.kyc-box-shadow {
  box-shadow: 0px 9.688126564025879px 20.990942001342773px -5.651406288146973px #5c696e1a;
  border: 1px solid #0000000d;
}
@-moz-document url-prefix() {
  html,
  body {
    scrollbar-color: transparent;
    scrollbar-width: none;
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background: transparent;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.menu {
  animation: fade--in 0.3s ease-in-out;
}

@keyframes fade--in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
