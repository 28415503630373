.title {
  width: 80%;
}
.link {
  width: 90%;
}
.description {
  width: 100%;
}
.shimmer {
  padding: 5px;
  width: 95%;
  height: 120px;
  margin: 10px auto;
  background: #ffffff;
}
.shimmer .image-card {
  height: 90px;
  width: 90px;
  float: right;
  border-radius: 8px;
}
.stroke {
  height: 35px;
  background: #777;
  margin-top: 20px;
}

.wrapper {
  width: 0px;
  animation: fullView 0.5s forwards linear;
}

@keyframes fullView {
  100% {
    width: 100%;
  }
}
.animate {
  animation: shimmer 3s;
  animation-iteration-count: infinite;
  background: linear-gradient(to right, #e6e6e6 5%, #cccccc 25%, #e6e6e6 35%);
  background-size: 1000px 100%;
}
@keyframes shimmer {
  from {
    background-position: -1000px 0;
  }
  to {
    background-position: 1000px 0;
  }
}
