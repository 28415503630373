.lineBreak{
    br {
      display: none;
    }
    @media (min-width: 768px) {
      br {
        display: block;
      }
    }
}
