.modalBackdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.modalContent {
  width: 530px;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  position: relative;
  @media screen and (max-width: 767px) {
    position: absolute;
    bottom: -10px;
    width: 100vw;
  }
}
